<template>
    <div class="dx-markdown-viewer" :class="{'is-preview': isPreview}">
        <VueMarkdown class="markdown answer" v-if="data" :source="data"></VueMarkdown>
    </div>
</template>
<script setup lang="ts">
import { defineProps, defineEmits, ref, watch } from 'vue';
import VueMarkdown from 'vue-markdown-render';

const props = defineProps<{
    src: string;
    highlightText?: any;
    isPreview?: boolean;
}>()
const emits = defineEmits(["onload", "onloadDone", "onloadFail"]);
const data = ref();
watch(() => [props.src, props.highlightText],
() => {
    if(props.src) {
        load();
    }
}, {
    immediate: true
})
 
function load() {
    fetch(props.src)
    .then((response) => {
        return response.text();
    })
    .then(text => {
        data.value = text;
        emits("onloadDone");
    })
}

function renderHighlight() {
    //
}
</script>
<style lang="scss" scoped>
.dx-markdown-viewer {
    width: 100%;
    padding: 1em;
    text-align: left;
    &.is-preview {
        font-size: 3px;
    }
}
</style>