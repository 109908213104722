<template>
  <teleport to="body">
    <DxToast />
  </teleport>
  <router-view/>
</template>
<script setup lang="ts">
import { computed, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import DxToast from './components/DxToast.vue';
import router from './router';
import userService from './services/user.service';
import { useAccountStore } from './store/account';
const t = useI18n();
const locale = computed(() => t.locale.value);
watch(locale,
() => {
  document.getElementById("app")?.setAttribute("lang", "" + t.locale.value);
},
{ immediate: true});

const useAccount = useAccountStore();
onMounted(() => {
  userService.login()
  .then(user => {
    if(user) {
      useAccount.account = user;
    } else {
      router.push({name: 'login'});
    }
  })
  .catch(() => {
    router.push({name: 'login'});
  })
})
</script>
<style lang="scss" src="./assets/styles/main.scss"></style>
