import { App } from 'vue';


const GlobalPlugin = {
  install(app: App) {
    // global
  }
};

export default GlobalPlugin;
