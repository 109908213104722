<template>
    <DxSettingMenu class="only-mobile"/>
    <div class="dx-account-menu dx-card dx-setting-menu only-desktop">
        <div class="menu-item">
            <dx-icon icon="switch-account" />
            {{ $t('topbar.account.changeAccount')}}
        </div>
        <div class="menu-item" @click="logout">
            <dx-icon icon="logout" />
            {{ $t('topbar.account.logout')}}
        </div>
    </div>
</template>
<script setup lang="ts">
import router from '@/router';
import userService from '@/services/user.service';
import { useAccountStore } from '@/store/account';
import DxSettingMenu from './DxSettingMenu.vue';

const useAccount = useAccountStore();
function logout() {
    userService.logout()
    .then(() => {
        useAccount.account = null;
        localStorage.removeItem('dx-currentLang');
        router.push({name: "login"});
    })
    .catch(() => {
        console.log("error");
    })
}

</script>
<style lang="scss">
.dx-account-menu {
    width: fit-content;
    min-width: auto;
    .menu-item {
        justify-content: flex-start;
        :last-child {
            min-width: auto;
        }
        &:hover {
            background-color: rgba($color1, 0.05);
        }
    }
}
</style>