<template>
    <div class="top-bar">
        <div class="menu-item only-mobile" @click="openSidebar">
          <dx-icon icon="humberger"/>
        </div>
        <div class="menu-item only-mobile">
          <slot name="pageTitle"></slot>
        </div>
        <div class="menu-item only-desktop">
            <inline-svg :src="require('@/assets/imgs/icons/notification.svg')" />
        </div>
        <DxDropdown class="menu-item only-desktop">
            <template v-slot:default="{open}">
                <inline-svg :src="require('@/assets/imgs/icons/setting.svg')" @click="open"/>
            </template>
            <template v-slot:dropdown>
                <DxSettingMenu />
            </template>
        </DxDropdown>
        <DxDropdown class="menu-item">
            <template v-slot:default="{open}">
                <div class="account-item" @click="open">
                    <inline-svg :src="require('@/assets/imgs/icons/account.svg')" />
                    <span class="label only-desktop">{{ account?.name }}</span>
                    <inline-svg class="suffix only-desktop" :src="require('@/assets/imgs/icons/chevron2-down.svg')" />
                </div>
            </template>
            <template v-slot:dropdown>
                <DxAccountMenu />
            </template>
        </DxDropdown>
    </div>
</template>
<script setup lang="ts">
import { useAccountStore } from '@/store/account';
import { computed, ref } from 'vue';
import DxAccountMenu from './DxAccountMenu.vue';
import DxDropdown from './DxDropdown.vue';
import DxSettingMenu from './DxSettingMenu.vue';

const useAccount = useAccountStore();

const account = computed(() => {
    return useAccount.account;
})

function openSidebar() {
    useAccount.openSidebar = true;
}
</script>
<style lang="scss" scoped>

.top-bar {
    margin-top: 2.5px;
    display: grid;
    grid-auto-flow: column;
    gap: 2rem;
    width: fit-content;
    min-width: fit-content;
    font-size: 12px;
    font-family: $font-family2;
    align-items: center;
    user-select: none;
    @media only screen and (max-width: 768px) {
        justify-content: space-between;
        width: 100% !important;
        border-bottom: 1px solid rgba($color1, 0.2);
        padding: 15px 20px;
        gap: 0;
    }
    .menu-item {
        cursor: pointer;
        position: relative;
        svg.suffix {
            width: 12px;
            height: 6px;
            opacity: 0.5;
            margin-left: 1em;
        }
        .label {
            margin-left: 0.5em;
        }
    }
    .account-item {
        display: flex;
        justify-self: center;
        align-items: center;
        .label {
            max-width: 15em;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
        }
    }
}
</style>