<template>
    <div class="page-container">
        <div class="page-container__header">
            <DxLanguagesOption v-model="languageOption" class="center-area" @on-select="translate"/>
            <TopBar :class="'topbar'" >
                <template v-slot:pageTitle>
                    <div class="dx-page-title">
                        <img src="@/assets/imgs/sidebar/file-translation.svg"/>
                        {{ $t('fileTranslationPage.title') }}
                    </div>
                </template>
            </TopBar>
        </div>
        <div class="page-container__body">
            <div class="dx-card translate-data-card" ref="dropArea" :class="{'loading': uploadLoading}">
                <DxSpinner class="spinner"/>
                <div class="translate-data-card__header--border" v-if="file" >
                    <div class="actions" v-if="resultFile">
                        <button class="action new-action dx-button dx-button-outline" @click="newTranslation">
                            <inline-svg :src="require('@/assets/imgs/icons/new-file-translation.svg')"/>
                            <div class="label">
                                {{ $t('fileTranslationPage.newTranslation') }}
                            </div>
                        </button>
                    </div>
                </div>
                <div class="translate-data-card__body dx-card-body">
                    <div class="dx-upload-button dupble-border" v-if="!file" ref="uploadButton">
                        <div class="dx-upload-button__body">
                            <dx-icon class="only-desktop" icon="upload" />
                            <dx-icon class="only-mobile" icon="plus" />
                            <div class="dx-upload-button__body__label" v-html="$t('fileTranslationPage.uploadFile')"></div>
                        </div>
                    </div>
                    <DxFileViewer v-if="file" :file="file" />
                </div>
            </div>
            <div class="dx-card translate-data-card--result" :class="{'has-data': resultFile, 'loading': translateLoading}">
                <DxSpinner class="spinner"/>
                <Transition>
                    <div class="translate-data-card__header">
                        <div class="actions">
                            <div class="action" @click="(resultFile as File).download()" :class="{'visibility-hidden': !resultFile || translateLoading}">
                                <inline-svg :src="require('@/assets/imgs/icons/download.svg')"/>
                                <div class="label only-desktop">
                                    {{ $t('fileTranslationPage.download') }}
                                </div>
                            </div>
                            <button class="action new-action dx-button dx-button-outline" @click="newTranslation" :class="{'visibility-hidden': !file}">
                                <inline-svg :src="require('@/assets/imgs/icons/new-file-translation.svg')"/>
                                <div class="label">
                                    {{ $t('fileTranslationPage.newTranslation') }}
                                </div>
                            </button>
                        </div>
                    </div>
                </Transition>
                <div class="translate-data-card__body">
                    <div class="illustration-image" v-if="!resultFile">
                        <div class="illustration-image__img1">
                            <img src="@/assets/imgs/file-translation/illustration_1.svg"/>
                        </div>
                        <span v-html="$t('fileTranslationPage.resultPlaceText')"></span>
                        <img class="illustration-image__img2" src="@/assets/imgs/file-translation/illustration_2.svg"/>
                    </div>
                    <DxFileViewer v-if="resultFile" :file="resultFile"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import DxFileViewer from '@/components/DxFileViewer.vue';
import DxLanguagesOption from '@/components/DxLanguagesOption.vue';
import DxSpinner from '@/components/DxSpinner.vue';
import TopBar from '@/components/TopBar.vue';
import { useLoading } from '@/composables/useLoading';
import { useToast } from '@/composables/useToast';
import { useUpload } from '@/composables/useUpload';
import { File, IFile } from '@/models/file';
import docService from '@/services/doc.service';
import llmService from '@/services/llm.service';
import { useSearchStore } from '@/store/search';
import { onMounted, onUnmounted, Ref, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const searchStore = useSearchStore();

const languageOption = ref();

const dropArea = ref();
const uploadButton = ref();
const uploadLoading = ref(false);

const resultFile = ref<IFile>();
const writing = ref(false);
const translateLoading = ref(false);

const file = ref();
useUpload({
    dropArea: dropArea,
    uploadButton: uploadButton,
    callback: async (_file: IFile) => {
        if(!_file) return;
        useLoading(true);
        // uploadLoading.value = true;
        await docService.uploadDoc(_file)
        .then(data => {
            file.value = _file;
            file.value.id = data.id;
            translate();
        })
        .catch((err) => {
            useToast({message: err.message, type: 'error'});
        }).finally(() => {
            useLoading(false);
            uploadLoading.value = false;
        });
    }
}) as Ref<IFile>;

onMounted(() => {
    if(searchStore.fileTranslateData) {
        languageOption.value = searchStore.fileTranslateData.languages;
        file.value = searchStore.fileTranslateData.file;
        resultFile.value = searchStore.fileTranslateData.resultFile;
    }
})

let controller:AbortController;
function translate() {
    if(file.value) {
        controller?.abort("Cancel request")
        resultFile.value = undefined;
        translateLoading.value = true;
        const fetch = llmService.fileTranslate(file.value.id,languageOption.value);
        controller = fetch.controller;
        fetch.promise
        .then((_file: IFile) => {
            translateLoading.value = false;
            resultFile.value = _file;
            searchStore.fileTranslateData = {};
            searchStore.fileTranslateData.languages = languageOption.value;
            searchStore.fileTranslateData.file = file;
            searchStore.fileTranslateData.resultFile = resultFile.value;
            translateLoading.value = false;
        })
        .catch((err) => {
            if (err.name == 'AbortError' || err == "Cancel request") {
                return;
            }
            useToast({message: err.message, type: 'error'});
            translateLoading.value = false;
        })
    }
}

function newTranslation() {
    controller?.abort("Cancel request");
    file.value = null;
    resultFile.value = undefined;
    writing.value = false;
    searchStore.fileTranslateData = null;
}

onUnmounted(() => {
    useLoading(false);
})
</script>
<style lang="scss" scoped>
.page-container__header {
    display: flex;
    position: relative;
    flex-wrap: wrap-reverse;
    .center-area {
        z-index: 1;
        position: absolute;
        left: 50%;
        transform: translate(-50%);
    }
    .topbar {
        margin-left: auto;
    }

    @media only screen and (max-width: 1200px) {
        > * {
            width: 100%;
        }
        .center-area {
            position: static;
            left: 0;
            transform: initial;
        }
        .topbar {
            margin-left: auto;
            width: fit-content;
            padding-bottom: 15px;
        }
    }
}
.page-container__body {
    flex: 1;
    margin-top: 20px;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    gap: 23px;
    overflow: auto;
    .translate-data-card {
        background-color: rgba($color: white, $alpha: 0.6);
        border: none;
        position: relative;
        display: flex;
        flex-direction: column;
        .spinner {
            display: none;
            position: absolute;
            top: 50%;
            left: 50%;
            translate: -50% -50%;
        }
        &.loading {
            >*:not(.spinner) {
                opacity: 0;
            }
            .spinner {
                display: block;
            }
        }
        &--result {
            @extend .translate-data-card;
            .translate-data-card__header {
                display: flex;
            }
        }
        &__header {
            font-size: 14px;
            margin: 0 5px;
            padding: 0 25px;
            height: 70px;
            line-height: 70px;
            display: none;
            &--border {
                @extend .translate-data-card__header;
                border-bottom: 1px solid rgba($color1, 0.3);
            }
        }
        
        &__body {
            flex: 1;
            min-height: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            overflow: auto;
            padding: 40px;
            font-size: 18px;
            &__result {
                height: 100%;
                width: 100%;
                &:not(.markdown) {
                    line-height: 25px;
                    font-weight: inherit;
                    white-space: break-spaces;
                     word-break: break-word;
                }
                &.writing {
                    &::after {
                        content: "...";
                        animation: writing 1s infinite;
                        font-size: 20px;
                        line-height: 0;
                        max-height: 0;
                        display: inline-block;
                    }
                }
                @keyframes writing {
                    0% {
                        content: "."
                    } 
                    25% {
                        content: ".."
                    }
                    50% {
                        content: "..."
                    }
                    100% {
                        content: "."
                    }
                }
            }
        }
        &__footer {
            padding: 25px;
            .dx-button {
                border: 1px solid $color4;
                color: $color4;
                margin-left: auto;
                height: 36px;
                padding: 0 40px;
            }
        }
    }
}

.actions {
    display: flex;
    align-items: center;
    font-size: 12px;
    width: 100%;
    .action {
        &:not(:first-child) {
            margin-left: 28px;
        }
        display: flex;
        align-items: center;
        height: 36px;
        cursor: pointer;
        border-radius: 8px;
        overflow: hidden;
        .label {
            flex: 1;
            overflow: hidden;
            margin-left: 0.5em;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        &:not(.new-action):hover {
            color: rgba($color1, 0.6);
        }
        &.new-action {
            margin-left: auto;
        }
    }
}

.illustration-image {
    position: absolute;
    inset: min(70px, 10%);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgba($color1, 0.4);
    &__img1 {
        align-self: flex-start;
        width: 50%;
        flex: 1;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: bottom;
            margin-left: 40px;
            padding-bottom: 2em;
        }
    }
    &__img2 {
        transform: translate(20%);
        width: 200px;
        max-width: 1000%;
        object-fit: contain;
        object-position: top;
        flex: 1;
    }
    span {
        width: 15em;
        max-width: 80%;
        text-align: center;
        font-size: 16px;
    }
}

@media only screen and (max-width: 768px), (max-aspect-ratio: 1/1) {
    .page-container {
        &__header {
            gap: 0;
            .dx-lang-options {
                padding: 15px 20px;
                :deep(.language) {
                    flex: 1;
                    min-width: 0;
                    .dx-select {
                        width: 100%;
                        max-width: 100%;
                        min-width: auto;
                        .dx-select-input {
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
        &__body {
            grid-auto-flow: row;
            grid-template-columns: 1fr;
            grid-template-rows: repeat(auto-fit, minmax(0, 1fr));
            margin-top: 0;
            min-height: 0;
            .translate-data-card {
                border-radius: 0px;
                &--result {
                    display: none;
                    &.has-data {
                        display: flex;
                    }
                    .actions {
                        justify-content: flex-end;
                        .action.new-action {
                            display: none;
                        }
                    }
                }
                &__header {
                    padding: 0 20px;
                    margin: 0;
                    display: flex;
                    height: 55px;
                }
                &__body {
                    padding: 0 40px !important;
                    font-size: 13px;
                }
            }
        }
    }

    .actions {
        .action.new-action {
            height: 32px;
        }
    }
}
</style>