import { IAccount, IPreference } from '@/models/account'
import { defineStore } from 'pinia'
import { ref } from 'vue';

export const useAccountStore = defineStore('account', () => {
    const account = ref<IAccount | null>();
    const openSidebar = ref(false);
    const preferences: IPreference = {theme: 'default', language: 'en', autoGenerateHistory: false};
    return {
        account,
        openSidebar,
        preferences
    }
})